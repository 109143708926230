const form = {
  formId: "send-notification-form",
  formField: {
    title: {
      name: "title",
      type: "text",
      errorMsgKey: "Title est obligatoire",
    },
    message: {
      name: "message",
      type: "string",
      errorMsgKey: "Message est obligatoire",
    },
    date: {
      name: "date",
      type: "date",
    },
    hour: {
      name: "hour",
      type: "number",
    },
    minute: {
      name: "minute",
      type: "number",
    },
  },
};

export default form;
