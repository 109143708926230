import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { findAllNewsletters } from "./newsletter.service";
import { Newsletter } from "types/newsletter.type";

export enum KeyQueryNewsletters {
  getallNewsletters = "getallNewsletters",
}

export const useGetAllNewsletters = (): UseQueryResult<Newsletter> => {
  return useQuery([KeyQueryNewsletters.getallNewsletters], () =>
    findAllNewsletters()
  );
};
