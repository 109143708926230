import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Overview(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox
        mt={5}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "50vh",
          justifyContent: "center",
        }}
      >
        <Grid>
          <MDTypography component="div" variant="h1">
            En cours de développement !
          </MDTypography>
        </Grid>
        <Grid>
          <MDTypography component="div" variant="p">
            Merci de votre patience
          </MDTypography>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Overview;
