import api from "config/axios.config";
import { Volunteer } from "types/volunteer.type";

export const findAllVolunteer = (): Promise<Volunteer> => {
  return api.get(`/volunteers`);
};

export const downloadVolunteers = async ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  try {
    return api.get(`/volunteers/download/${startDate}/${endDate}`, {
      responseType: "blob",
    });
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
