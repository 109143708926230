import { Card, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import {
  useDeleteNotification,
  useGetNotificationPending,
} from "../notification.hook";
import MDButton from "components/MDButton";
import { Notification } from "../notification.type";

function TableNotification(): JSX.Element {
  const deleteNotification = useDeleteNotification();
  const { data } = useGetNotificationPending();

  const dataTableNotification: any = {
    columns: [
      {
        Header: "Titre",
        accessor: "title",
      },
      {
        Header: "Text",
        accessor: "body",
      },
      {
        Header: "prévu",
        accessor: "scheduled",
        Cell: ({ row }: any) =>
          moment(row.original.scheduled).format("DD-MM-YYYY HH:mm"),
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row }: any) => {
          return (
            <MDBox display="flex" alignItems="center">
              <MDButton
                variant="text"
                color="error"
                onClick={() => handleDelete(row.original)}
              >
                <Icon>delete</Icon>
              </MDButton>
            </MDBox>
          );
        },
      },
    ],
    rows: data,
  };

  const handleDelete = async (notification: Notification) => {
    const confirmed = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cet élément ?"
    );
    if (confirmed) {
      // Code de suppression
      await deleteNotification.mutateAsync(notification?.id);
    }
  };

  return (
    <MDBox>
      <Card>
        <MDBox p={3} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            Notifications en attente
          </MDTypography>
          <MDTypography variant="button" color="text">
            Liste des notifications en attente
          </MDTypography>
        </MDBox>
        {data && <DataTable table={dataTableNotification} canSearch />}
      </Card>
    </MDBox>
  );
}

export default TableNotification;
