import { AuthContext } from "context/auth-context/auth.context";
import { useContext } from "react";

import { Navigate, useLocation } from "react-router-dom";

function RequireAuth({ children }: { children: JSX.Element }) {
  const { currentUserFirebase } = useContext(AuthContext);
  const location = useLocation();

  if (!currentUserFirebase) {
    return <Navigate to="/authentication/sign-in" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth;
