import api from "config/axios.config";
import { Content, DateVolunteer } from "./content.type";

export const findAllDateVolunteer = (): Promise<DateVolunteer[]> => {
  return api.get(`/dateVolunteer`);
};

export const findAllContents = (): Promise<Content[]> => {
  return api.get(`/content`);
};

export async function updateContents(contents: Content[]) {
  return api.patch(`content/bulk`, contents);
}

export async function updateDateVolunteer(dateVolunteer: DateVolunteer[]) {
  return api.patch(`dateVolunteer/bulk`, dateVolunteer);
}
