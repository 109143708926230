import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import FormDownload from "./components/form-download";
import { useGetAllNewsletters } from "./newsletter.hook";

function Newsletter(): JSX.Element {
  const { data } = useGetAllNewsletters();

  const dataTableNewsletter: any = {
    columns: [
      {
        Header: "email",
        accessor: "email",
      },
      {
        Header: "Date added",
        accessor: "createdAt",
        Cell: ({ row }: any) =>
          moment(row.original.createdAt).format("DD-MM-YYYY"),
      },
    ],
    rows: data,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Telechargement
            </MDTypography>
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            <FormDownload />
          </MDBox>
        </Card>
      </MDBox>
      <MDBox>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Abonnés Infolettre
            </MDTypography>
            <MDTypography variant="button" color="text">
              Liste des abonnés
            </MDTypography>
          </MDBox>
          {data && <DataTable table={dataTableNewsletter} canSearch />}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Newsletter;
