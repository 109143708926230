import api from "config/axios.config";
import { Newsletter } from "types/newsletter.type";

export const findAllNewsletters = (): Promise<Newsletter> => {
  return api.get(`/newsletters`);
};

export const downloadNewsletters = async ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  try {
    return api.get(`/newsletters/download/${startDate}/${endDate}`, {
      responseType: "blob",
    });
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
