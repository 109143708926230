import MDBox from "components/MDBox";
import { ETypeContent } from "../content.type";
import ContentUpdate from "./content-update";

function SectionMusic(): JSX.Element {
  return (
    <MDBox>
      <MDBox>
        <ContentUpdate
          title="Famille section 1"
          typeContent={ETypeContent.family1}
          addLink1={false}
          addLink2={false}
          addSubCentent={true}
          addSubBody={true}
        />
        <ContentUpdate
          title="Famille section 2"
          typeContent={ETypeContent.family2}
          addLink1={false}
          addLink2={false}
          addSubCentent={true}
          addSubBody={true}
        />
        <ContentUpdate
          title="Famille section 3"
          typeContent={ETypeContent.family3}
          addLink1={false}
          addLink2={false}
          addSubCentent={true}
          addSubBody={true}
        />
      </MDBox>
    </MDBox>
  );
}

export default SectionMusic;
