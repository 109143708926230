export enum ERangeAge {
  AGE_14 = "AGE_14",
  AGE_15_18 = "AGE_15_18",
  AGE_18_60 = "AGE_18_60",
  AGE_60 = "AEG_60",
}

export enum ESectors {
  SECTOR_1 = "1",
  SECTOR_2 = "2",
  SECTOR_3 = "3",
  SECTOR_4 = "4",
  SECTOR_5 = "5",
  SECTOR_6 = "6",
  SECTOR_7 = "7",
  SECTOR_8 = "8",
  SECTOR_9 = "9",
  SECTOR_10 = "10",
  SECTOR_11 = "11",
  SECTOR_12 = "12",
  SECTOR_13 = "13",
  SECTOR_14 = "14",
  SECTOR_15 = "15",
  SECTOR_16 = "16",
  SECTOR_17 = "17",
  SECTOR_18 = "18",
}
