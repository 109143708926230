import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  deleteNotification,
  getNotificationsPending,
  sendPushNotification,
} from "./notification.service";
import { PushNotification } from "./notification.type";
import { Notification } from "./notification.type";

export enum KeyQueryNotification {
  getallNotification = "getallNotification",
  getNotificationPending = "getNotificationPending",
}

export const useSendPushNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: PushNotification) => sendPushNotification(data),
    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryNotification.getNotificationPending],
        (prevNotifications: Notification[] | undefined) => [
          ...prevNotifications,
          result,
        ]
      );
    },
  });
};

export const useGetNotificationPending = (): UseQueryResult<Notification[]> => {
  return useQuery([KeyQueryNotification.getNotificationPending], () =>
    getNotificationsPending()
  );
};

export const useDeleteNotification = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (notificationId: string) => deleteNotification(notificationId),
    onSuccess: async (result) => {
      queryClient.setQueryData(
        [KeyQueryNotification.getNotificationPending],
        (prevNotifications: Notification[] | undefined) => {
          return (
            prevNotifications?.filter(
              (notification) => notification.id !== result.id
            ) || []
          );
        }
      );
    },
  });
};
