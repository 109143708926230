import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormNotification from "./components/form-notification";
import TableNotification from "./components/table-notification";

function Notification(): JSX.Element {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Notification
            </MDTypography>
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            <FormNotification />
          </MDBox>
        </Card>
      </MDBox>
      <MDBox pb={3}>
        <TableNotification />
      </MDBox>
    </DashboardLayout>
  );
}

export default Notification;
