import DOMPurify from "dompurify";

const cleanHtml = (html: string) => {
  const allowedTags = [
    "p",
    "strong",
    "em",
    "code",
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "ul",
    "ol",
    "li",
  ];

  const cleanResult = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: allowedTags,
    ALLOWED_ATTR: [],
  });

  return cleanResult;
};

export default cleanHtml;
