import { Autocomplete, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import { Form, Formik } from "formik";
import form from "../schemas/form";
import initialValues from "../schemas/initialValues";
import validations from "../schemas/validations";
import FormField from "components/FormField/FormField";
import { useSendPushNotification } from "../notification.hook";
import selectData from "../notification.data";
import moment from "moment";

const FormNotification = () => {
  const { formId, formField } = form;
  const sendPushNotification = useSendPushNotification();

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      const scheduled = values.date
        ? moment(
            `${values.date} ${values.hour || "12"}:${values.minute || "00"}:00`
          ).format("YYYY-MM-DD HH:mm:ss")
        : null;

      const data = {
        title: values.title,
        body: values.message,
        scheduled,
      };
      await sendPushNotification.mutateAsync(data);
      window.location.reload();
    } catch (error: any) {
      console.error(error.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={handleSubmit}
    >
      {({ values, errors, setFieldValue, dirty, isSubmitting }) => {
        const { title, message, date, hour, minute } = formField;
        const { title: titleV, date: dateV } = values;

        return (
          <Form id={formId} autoComplete="off">
            <MDBox pb={2}>
              <Grid container spacing={3}>
                <Grid container spacing={3} pl={3}>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      type={title.type}
                      label={"Titre *"}
                      name={title.name}
                      value={titleV}
                      success={titleV?.length > 0 && !errors.title}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MDBox mb={1} lineHeight={0} display="inline-block">
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Description *
                    </MDTypography>
                  </MDBox>
                  <MDEditor
                    value={(content) => setFieldValue(message.name, content)}
                    maxLength={500}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox pb={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="button" color="text">
                    Date
                  </MDTypography>
                  <FormField type={date.type} name={date.name} value={dateV} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDTypography variant="button" color="text">
                    Heure
                  </MDTypography>
                  <Autocomplete
                    options={selectData.hours}
                    onChange={(e, value) => {
                      setFieldValue(hour.name, value !== null ? value : "12");
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        name={hour.name}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MDTypography variant="button" color="text">
                    Minute
                  </MDTypography>
                  <Autocomplete
                    options={selectData.minutes}
                    onChange={(e, value) => {
                      setFieldValue(minute.name, value !== null ? value : "00");
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        name={minute.name}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </MDBox>
            <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <MDButton
                    disabled={!dirty || isSubmitting}
                    type="submit"
                    variant="gradient"
                    color="info"
                  >
                    Valider
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "inherit",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormNotification;
