const form = {
  formId: "download-volunteers-form",
  formField: {
    startDate: {
      name: "startDate",
      type: "date",
      errorMsgKey: "Date de debut est obligatoire",
    },
    endDate: {
      name: "endDate",
      type: "date",
      errorMsgKey: "Date de fin est obligatoire",
    },
  },
};

export default form;
