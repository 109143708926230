import MDBox from "components/MDBox";
import { ETypeContent } from "../content.type";
import ContentUpdate from "./content-update";

function SectionTicket(): JSX.Element {
  return (
    <MDBox>
      <MDBox>
        <ContentUpdate
          title="Billetterie section 1"
          typeContent={ETypeContent.ticket1}
          addPeriod={false}
          addLink2={false}
        />
        <ContentUpdate
          title="Billetterie section 2"
          typeContent={ETypeContent.ticket2}
          addPeriod={false}
          addLink2={false}
        />
        <ContentUpdate
          title="Billetterie section 3"
          typeContent={ETypeContent.ticket3}
          addPeriod={false}
          addLink2={false}
        />
        <ContentUpdate
          title="Billetterie section 4"
          typeContent={ETypeContent.ticket4}
          addPeriod={false}
          addLink2={false}
        />
        <ContentUpdate
          title="Billetterie section 5"
          typeContent={ETypeContent.ticket5}
          addPeriod={false}
          addLink2={false}
        />
      </MDBox>
    </MDBox>
  );
}

export default SectionTicket;
