import MDBox from "components/MDBox";
import { ETypeContent } from "../content.type";
import ContentUpdate from "./content-update";

function SectionMusic(): JSX.Element {
  return (
    <MDBox>
      <MDBox>
        <ContentUpdate
          title="Musique section 1"
          typeContent={ETypeContent.music1}
        />
        <ContentUpdate
          title="Musique section 2"
          typeContent={ETypeContent.music2}
        />
        <ContentUpdate
          title="Musique section 3"
          typeContent={ETypeContent.music3}
        />
        <ContentUpdate
          title="Musique section 4"
          typeContent={ETypeContent.music4}
          addLink2={false}
          addPeriod={false}
        />
      </MDBox>
    </MDBox>
  );
}

export default SectionMusic;
