import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Form, Formik } from "formik";
import initialValues from "../schemas/initialValues";
import validations from "../schemas/validations";
import form from "../schemas/form";
import MDTypography from "components/MDTypography";
import { downloadNewsletters } from "../newsletter.service";

const FormDownload = () => {
  const { formId, formField } = form;

  const handleSubmit = async (values: any, { resetForm }: any) => {
    try {
      const response = await downloadNewsletters({
        startDate: values.startDate,
        endDate: values.endDate,
      });
      const blobData = new Blob([response as any]);

      const href = URL.createObjectURL(blobData);

      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `newsletter.csv`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      resetForm();
    } catch (error: any) {
      console.error(error.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validations}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, setFieldValue, dirty, isSubmitting }) => {
        const { startDate: startDateV, endDate: endDateV } = values;
        const { startDate, endDate } = formField;
        return (
          <Form id={formId} autoComplete="off">
            <MDBox>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <MDTypography variant="button" color="text">
                    Date de debut
                  </MDTypography>
                  <FormField
                    type={startDate.type}
                    name={startDate.name}
                    value={startDateV}
                    error={errors.startDate && touched.startDate}
                    success={startDateV?.length > 0 && !errors.startDate}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <MDTypography variant="button" color="text">
                    Date de fin
                  </MDTypography>
                  <FormField
                    type={endDate.type}
                    name={endDate.name}
                    value={endDateV}
                    error={errors.endDate && touched.endDate}
                    success={endDateV?.length > 0 && !errors.endDate}
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <MDButton
                    disabled={!dirty || isSubmitting}
                    type="submit"
                    variant="gradient"
                    color="info"
                  >
                    Valider
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "inherit",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                  </MDButton>
                </Grid>
              </Grid>
            </MDBox>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormDownload;
