import { Card } from "@mui/material";
import profilePicture from "assets/images/profil-default.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import moment from "moment";
import FormDownload from "./components/form-download";
import { useGetAllVolunteer } from "./volunter.hook";
import { ERangeAge, ESectors } from "./volunter.type";

function Volunteer(): JSX.Element {
  const { data } = useGetAllVolunteer();

  const rangeAge = {
    [ERangeAge.AGE_14]: "Moins de 14 ans",
    [ERangeAge.AGE_15_18]: "15 à 18 ans",
    [ERangeAge.AGE_18_60]: "18 à 60 ans",
    [ERangeAge.AGE_60]: "60 ans et plus",
  };

  const sectorsEnum = {
    [ESectors.SECTOR_1]: "Surveillances des sites",
    [ESectors.SECTOR_2]:
      "Surveillance lors de la course L'Express H2O Desjardins du dimanche",
    [ESectors.SECTOR_3]:
      "Préposé(e) aux points d'eau de la course L'Express H2O Desjardins",
    [ESectors.SECTOR_4]:
      "Surveillances des activités pour les jeunes enfants au Parc Brunet",
    [ESectors.SECTOR_5]:
      "Surveillances des activités pour les ados / jeunes adultes à la Zone Challenge",
    [ESectors.SECTOR_6]: "Surveillance à vélo du trajet de course",
    [ESectors.SECTOR_7]: "Surveillance des activités au Parc récréonautique",
    [ESectors.SECTOR_8]: "Premiers soins pour la course à pieds du dimanche",
    [ESectors.SECTOR_9]: "Service de bar",
    [ESectors.SECTOR_10]: "Billetterie / guérites",
    [ESectors.SECTOR_11]: "Accueil au quartier général",
    [ESectors.SECTOR_12]: "Cuisine",
    [ESectors.SECTOR_13]: "Commissionaire",
    [ESectors.SECTOR_14]: "Aménagement et déménagement",
    [ESectors.SECTOR_15]: "Préposé(e) aux articles promotionnels",
    [ESectors.SECTOR_16]: "Éco H20 - équipe verte",
    [ESectors.SECTOR_17]: "Porteur ou accompagnateur de la mascotte Loufto",
    [ESectors.SECTOR_18]: "Coordination",
  };

  const dataTableVolunteer = {
    columns: [
      {
        Header: "photo",
        accessor: "photo",
        width: "10%",
        Cell: ({ row }: any) => {
          return (
            <MDBox display="flex" alignItems="center">
              <MDBox
                component="img"
                src={row.original.photo || profilePicture}
                alt="Brand"
                width="40px"
                height="40px"
                borderRadius="100%"
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </MDBox>
          );
        },
      },
      {
        Header: "name",
        accessor: "name",
        width: "20%",
      },
      {
        Header: "age",
        accessor: "age",
        width: "20%",
        Cell: ({ row }: any) => rangeAge[row.original.age as ERangeAge],
      },
      {
        Header: "phone",
        accessor: "phone",
        width: "20%",
        Cell: ({ row }: any) =>
          row.original.phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"),
      },
      {
        Header: "Secteur",
        accessor: "sectors",
        width: "25%",
        Cell: ({ row }: any) => {
          const sectorsText = row.original.sectors
            .map((sector: ESectors) => {
              const sectorName = sectorsEnum[sector];
              return sectorName ? sectorName : "";
            })
            .join(", ");
          const displayText =
            sectorsText.length > 50
              ? `${sectorsText.slice(0, 50)}...`
              : sectorsText;

          return displayText;
        },
      },
      {
        Header: "Date added",
        accessor: "createdAt",
        width: "15%",
        Cell: ({ row }: any) =>
          moment(row.original.createdAt).format("DD-MM-YYYY"),
      },
    ],
    rows: data,
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pb={3}>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Telechargement
            </MDTypography>
          </MDBox>
          <MDBox p={3} lineHeight={1}>
            <FormDownload />
          </MDBox>
        </Card>
      </MDBox>
      <MDBox>
        <Card>
          <MDBox p={3} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              Volontaires
            </MDTypography>
            <MDTypography variant="button" color="text">
              Liste des volontaires
            </MDTypography>
          </MDBox>
          {data && <DataTable table={dataTableVolunteer} canSearch />}
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default Volunteer;
