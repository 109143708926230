import { Card, CircularProgress, Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useGetAllContent, useUpdateContents } from "../content.hook";
import { Content, ETypeContent } from "../content.type";

type InitialValues = {
  dateFestivalFr: string;
  dateFestivalEn: string;
  dateMusicFr: string;
  dateMusicEn: string;
  dateFamilyFr: string;
  dateFamilyEn: string;
  dateSportFr: string;
  dateSportEn: string;
  dateActivityFr: string;
  dateActivityEn: string;
};

function DateFestival(): JSX.Element {
  const { data, isLoading } = useGetAllContent();
  const updateContents = useUpdateContents();

  const initialValues: InitialValues = {
    dateFestivalFr: data?.filter(
      (content) => content.id === ETypeContent.dateFestival
    )[0].title_fr,
    dateFestivalEn: data?.filter(
      (content) => content.id === ETypeContent.dateFestival
    )[0].title_en,
    dateMusicFr: data?.filter(
      (content) => content.id === ETypeContent.dateMusic
    )[0].title_fr,
    dateMusicEn: data?.filter(
      (content) => content.id === ETypeContent.dateMusic
    )[0].title_en,
    dateFamilyFr: data?.filter(
      (content) => content.id === ETypeContent.dateFamily
    )[0].title_fr,
    dateFamilyEn: data?.filter(
      (content) => content.id === ETypeContent.dateFamily
    )[0].title_en,
    dateSportFr: data?.filter(
      (content) => content.id === ETypeContent.dateSport
    )[0].title_fr,
    dateSportEn: data?.filter(
      (content) => content.id === ETypeContent.dateSport
    )[0].title_en,
    dateActivityFr: data?.filter(
      (content) => content.id === ETypeContent.dateActivity
    )[0].title_fr,
    dateActivityEn: data?.filter(
      (content) => content.id === ETypeContent.dateActivity
    )[0].title_en,
  };

  const validations = Yup.object().shape({
    dateFestivalFr: Yup.string().required("champs obligatoire"),
    dateFestivalEn: Yup.string().required("champs obligatoire"),
    dateMusicEn: Yup.string().required("champs obligatoire"),
    dateMusicFr: Yup.string().required("champs obligatoire"),
    dateFamilyEn: Yup.string().required("champs obligatoire"),
    dateFamilyFr: Yup.string().required("champs obligatoire"),
    dateSportEn: Yup.string().required("champs obligatoire"),
    dateSportFr: Yup.string().required("champs obligatoire"),
    dateActivityEn: Yup.string().required("champs obligatoire"),
    dateActivityFr: Yup.string().required("champs obligatoire"),
  });

  const handleSubmit = async (values: InitialValues, { resetForm }: any) => {
    try {
      const contents: Content[] = [
        {
          id: ETypeContent.dateFestival,
          title_fr: values.dateFestivalFr,
          title_en: values.dateFestivalEn,
        },
        {
          id: ETypeContent.dateMusic,
          title_fr: values.dateMusicFr,
          title_en: values.dateMusicEn,
        },
        {
          id: ETypeContent.dateFamily,
          title_fr: values.dateFamilyFr,
          title_en: values.dateFamilyEn,
        },
        {
          id: ETypeContent.dateSport,
          title_fr: values.dateSportFr,
          title_en: values.dateSportEn,
        },
        {
          id: ETypeContent.dateActivity,
          title_fr: values.dateActivityFr,
          title_en: values.dateActivityEn,
        },
      ];
      await updateContents.mutateAsync(contents);
      resetForm({ values });
    } catch (error: any) {
      console.error("error:", error);
      alert(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
        >
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <MDBox mt={4}>
          <Card style={{ overflowY: "auto" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, dirty, isSubmitting }) => {
                const {
                  dateFestivalFr: dateFestivalFrV,
                  dateFestivalEn: dateFestivalEnV,
                  dateMusicFr: dateMusicFrV,
                  dateMusicEn: dateMusicEnV,
                  dateFamilyFr: dateFamilyFrV,
                  dateFamilyEn: dateFamilyEnV,
                  dateSportFr: dateSportFrV,
                  dateSportEn: dateSportEnV,
                  dateActivityFr: dateActivityFrV,
                  dateActivityEn: dateActivityEnV,
                } = values;

                return (
                  <Form id={"form-date-festival"} autoComplete="off">
                    <MDBox my={3} m={4}>
                      <MDBox mb={6}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} lg={6}>
                            <MDTypography variant="h4" fontWeight="medium">
                              Date festival
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mt={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Fastival - FR"}
                              name={"dateFestivalFr"}
                              value={dateFestivalFrV}
                              placeholder={"11 > 14 juillet 2024"}
                              error={
                                errors.dateFestivalFr && touched.dateFestivalFr
                              }
                              success={
                                dateFestivalFrV?.length > 0 &&
                                !errors.dateFestivalFr
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Fastival - EN"}
                              name={"dateFestivalEn"}
                              value={dateFestivalEnV}
                              placeholder={"July 11 > 14, 2024"}
                              error={
                                errors.dateFestivalEn && touched.dateFestivalEn
                              }
                              success={
                                dateFestivalEnV?.length > 0 &&
                                !errors.dateFestivalEn
                              }
                            />
                          </Grid>
                        </Grid>
                      </MDBox>

                      <MDBox mt={3}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Musique - FR"}
                              name={"dateMusicFr"}
                              value={dateMusicFrV}
                              placeholder={"11 > 14 juillet 2024"}
                              error={errors.dateMusicFr && touched.dateMusicFr}
                              success={
                                dateMusicFrV?.length > 0 && !errors.dateMusicFr
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Musique - EN"}
                              name={"dateMusicEn"}
                              value={dateMusicEnV}
                              placeholder={"July 11 > 14, 2024"}
                              error={errors.dateMusicEn && touched.dateMusicEn}
                              success={
                                dateMusicEnV?.length > 0 && !errors.dateMusicEn
                              }
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mt={3}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Famille / activités gratuites - FR"}
                              name={"dateFamilyFr"}
                              value={dateFamilyFrV}
                              placeholder={"11 > 14 juillet 2024"}
                              error={
                                errors.dateFamilyFr && touched.dateFamilyFr
                              }
                              success={
                                dateFamilyFrV?.length > 0 &&
                                !errors.dateFamilyFr
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Famille / activités gratuites - EN"}
                              name={"dateFamilyEn"}
                              value={dateFamilyEnV}
                              placeholder={"July 11 > 14, 2024"}
                              error={
                                errors.dateFamilyEn && touched.dateFamilyEn
                              }
                              success={
                                dateFamilyEnV?.length > 0 &&
                                !errors.dateFamilyEn
                              }
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mt={3}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Sport - FR"}
                              name={"dateSportFr"}
                              value={dateSportFrV}
                              placeholder={"11 > 14 juillet 2024"}
                              error={errors.dateSportFr && touched.dateSportFr}
                              success={
                                dateSportFrV?.length > 0 && !errors.dateSportFr
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Fastival - EN"}
                              name={"dateSportEn"}
                              value={dateSportEnV}
                              placeholder={"July 11 > 14, 2024"}
                              error={errors.dateSportEn && touched.dateSportEn}
                              success={
                                dateSportEnV?.length > 0 && !errors.dateSportEn
                              }
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox mt={3}>
                        <Grid container spacing={4}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Activités - FR"}
                              name={"dateActivityFr"}
                              value={dateActivityFrV}
                              placeholder={"11 > 14 juillet 2024"}
                              error={
                                errors.dateActivityFr && touched.dateActivityFr
                              }
                              success={
                                dateActivityFrV?.length > 0 &&
                                !errors.dateActivityFr
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Date Activités - EN"}
                              name={"dateActivityEn"}
                              value={dateActivityEnV}
                              placeholder={"July 11 > 14, 2024"}
                              error={
                                errors.dateActivityEn && touched.dateActivityEn
                              }
                              success={
                                dateActivityEnV?.length > 0 &&
                                !errors.dateActivityEn
                              }
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox display="flex" justifyContent="flex-end">
                        <MDButton
                          disabled={!dirty || isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="info"
                        >
                          Valider
                          {isSubmitting && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "inherit",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </MDBox>
      )}
    </>
  );
}

export default DateFestival;
