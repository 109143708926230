import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import CoverLayout from "layouts/authentication/Layout";
import { useState } from "react";
import { sendVerificationEmail } from "services/firebase.service";

function VerifyEmail(): JSX.Element {
  const [alertSendEmail, setAlertSendEmail] = useState<string | null>(null);
  const [isSend, setIsSend] = useState(true);

  const handleSendEmail = async () => {
    try {
      await sendVerificationEmail();
      setIsSend(true);
      setAlertSendEmail("Un email de verification a été envoyé");
    } catch (error: any) {
      setIsSend(false);
      setAlertSendEmail(error.message);
    }
  };

  return (
    <CoverLayout>
      {
        <MDSnackbar
          color={isSend ? "success" : "error"}
          icon={isSend ? "notifications" : "warning"}
          title="Verification d'email"
          content={alertSendEmail}
          open={!!alertSendEmail}
          onClose={() => setAlertSendEmail(null)}
          close={() => setAlertSendEmail(null)}
          bgWhite
        />
      }
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            {"Vérification de l'email"}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Vous recevrez un e-mail dans un délai maximum de 60 secondes
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={1}>
          <MDBox component="form" role="form">
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => window.location.reload()}
                  fullWidth
                >
                  Actualiser
                </MDButton>
              </Grid>
              <Grid item xs={6}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => handleSendEmail()}
                  fullWidth
                >
                  envoyer email
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default VerifyEmail;
