import Card from "@mui/material/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import CoverLayout from "layouts/authentication/Layout";
import MDSnackbar from "components/MDSnackbar";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { sendEmailResetPassword } from "services/firebase.service";

function Cover(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [alertSendEmail, setAlertSendEmail] = useState<string | null>(null);
  const [isSend, setIsSend] = useState(true);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("Email incorrect"))
      .required("Email obligatoire"),
  });

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { email } = values;
        await sendEmailResetPassword(email);
        setIsSend(true);
        setAlertSendEmail(t("auth.resetPassword.alertSendEmail"));

        setTimeout(() => {
          navigate("/authentication/sign-in");
        }, 5000);
      } catch (error: any) {
        setIsSend(false);
        setAlertSendEmail(error.message);
      }
    },
  });

  return (
    <CoverLayout>
      {
        <MDSnackbar
          color={isSend ? "success" : "error"}
          icon={isSend ? "notifications" : "warning"}
          title={t("auth.resetPassword.title")}
          content={alertSendEmail}
          open={!!alertSendEmail}
          onClose={() => setAlertSendEmail(null)}
          close={() => setAlertSendEmail(null)}
          bgWhite
        />
      }
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox mb={3} textAlign="center">
            <MDTypography
              variant="h4"
              p={1}
              fontWeight="medium"
              color="info"
              mt={1}
            >
              MOT DE PASSE PERDU
            </MDTypography>
          </MDBox>
          <MDBox
            component="form"
            role="form"
            onSubmit={(e: any) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <MDBox mb={4}>
              <MDInput
                type="email"
                name="email"
                label={t("form.email")}
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={
                  formik.touched.email && (
                    <MDTypography
                      variant="inherit"
                      fontWeight="regular"
                      color="error"
                      mt={1}
                    >
                      {formik.errors.email}
                    </MDTypography>
                  )
                }
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                {t("auth.resetPassword.reset")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
