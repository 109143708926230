import { auth } from "config/firebase.config";
import {
  NextOrObserver,
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from "firebase/auth";
import { CreateAdmin } from "types/admin.type";

export const signInUser = async (email: string, password: string) => {
  if (!email || !password) {
    throw new Error("L'adresse e-mail et le mot de passe sont requis.");
  }

  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    return user;
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;

    switch (errorCode) {
      case "auth/invalid-email":
        throw new Error("L'adresse e-mail n'est pas valide.");
      case "auth/user-disabled":
        throw new Error("Ce compte utilisateur a été désactivé.");
      case "auth/user-not-found":
      case "auth/wrong-password":
        throw new Error("Adresse e-mail ou mot de passe incorrect.");
      default:
        throw new Error(errorMessage);
    }
  }
};

export const userStateListener = (callback: NextOrObserver<User>) => {
  return onAuthStateChanged(auth, callback);
};

export const SignOutUser = async () => await signOut(auth);

export const createAdminEmailPassword = async (createAdmin: CreateAdmin) => {
  try {
    const { email, password, lastName, firstName } = createAdmin;
    await createUserWithEmailAndPassword(auth, email, password);

    await updateProfile(auth.currentUser, {
      displayName: `${firstName} ${lastName}`,
    });

    await sendVerificationEmail();
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;

    switch (errorCode) {
      case "auth/email-already-in-use":
        throw new Error(
          "Un compte associé à cette adresse e-mail a déjà été créé. Veuillez vous connecter avec vos identifiants existants ou réinitialiser votre mot de passe si vous avez oublié vos informations d'identification."
        );
      default:
        throw new Error(errorMessage);
    }
  }
};
// create function sendVerificationEmail
export const sendVerificationEmail = async () => {
  try {
    await sendEmailVerification(auth.currentUser);
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;

    switch (errorCode) {
      case "auth/too-many-requests":
        throw new Error(
          "Limite d'envoi d'e-mails dépassée. Veuillez attendre un moment avant de réessayer."
        );
      default:
        throw new Error(errorMessage);
    }
  }
};

export const sendEmailResetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;

    switch (errorCode) {
      case "auth/too-many-requests":
        throw new Error(
          "Limite d'envoi d'e-mails dépassée. Veuillez attendre un moment avant de réessayer."
        );
      default:
        throw new Error(errorMessage);
    }
  }
};

export const getAuthToken = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user
          .getIdToken()
          .then((token) => resolve(token))
          .catch((error) => reject(error))
          .finally(() => unsubscribe());
      } else {
        resolve(null);
        unsubscribe();
      }
    });
  });
};
