import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { IconButton, InputAdornment } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import { useFormik } from "formik";
import CoverLayout from "layouts/authentication/Layout";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { signInUser } from "services/firebase.service";
import * as Yup from "yup";

const SignIn = (): JSX.Element => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [errorAlert, setErrorAlert] = useState<string | null>(null);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(t("Email incorrect"))
      .required("Email obligatoire"),
    password: Yup.string().required(t("Mot de passe obligatoire")),
  });

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const { email, password } = values;
        await signInUser(email, password);
      } catch (error: any) {
        setErrorAlert(error.message);
      }
    },
  });

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <CoverLayout>
      {
        <MDSnackbar
          color="error"
          icon="warning"
          title="Authentification"
          content={errorAlert}
          open={!!errorAlert}
          onClose={() => setErrorAlert(null)}
          close={() => setErrorAlert(null)}
          bgWhite
        />
      }
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox mb={3} textAlign="center">
            <MDTypography
              variant="h4"
              p={1}
              fontWeight="medium"
              color="info"
              mt={1}
            >
              CONNEXION
            </MDTypography>
          </MDBox>
          <MDBox
            component="form"
            role="form"
            onSubmit={(e: any) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <MDBox mb={2}>
              <MDInput
                type="email"
                name="email"
                label={t("form.email")}
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={
                  formik.touched.email && (
                    <MDTypography
                      variant="inherit"
                      fontWeight="regular"
                      color="error"
                      mt={1}
                    >
                      {formik.errors.email}
                    </MDTypography>
                  )
                }
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? "text" : "password"}
                name="password"
                label={t("form.password")}
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={
                  formik.touched.password && (
                    <MDTypography
                      variant="inherit"
                      fontWeight="regular"
                      color="error"
                      mt={1}
                    >
                      {formik.errors.password}
                    </MDTypography>
                  )
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton type="submit" variant="gradient" color="info" fullWidth>
                SE CONNECTER
              </MDButton>
            </MDBox>
            <MDBox mt={3} textAlign="center">
              <MDTypography
                component={Link}
                to="/authentication/sign-up"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Creer un compte
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1} textAlign="center">
              <MDTypography
                component={Link}
                to="/authentication/reset-password"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Mot de passe oublié
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

export default SignIn;
