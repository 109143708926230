// Logout.jsx
import { AuthContext } from "context/auth-context/auth.context";
import { useContext, useEffect } from "react";

const Logout = () => {
  const { signOut } = useContext(AuthContext);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return <div>Logging out...</div>;
};

export default Logout;
