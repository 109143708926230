import checkout from "pages/notification/schemas/form";

const {
  formField: { title, message },
} = checkout;

const initialValues = {
  [title.name]: "",
  [message.name]: "",
};

export default initialValues;
