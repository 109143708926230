import Icon from "@mui/material/Icon";
import profilePicture from "assets/images/profil-default.png";
import MDAvatar from "components/MDAvatar";
import ResetCover from "pages/auth/ResetPassword";
import SignIn from "pages/auth/SignIn";
import SignUp from "pages/auth/SignUp";
import VerifyEmail from "pages/auth/VerifyEmail";
import Logout from "pages/auth/logout";
import Newsletter from "pages/newsletter";
import Notification from "pages/notification";
import ThankYou from "pages/thank-you";
import UpdateContent from "pages/content";
import Volunteer from "pages/volunteer";
import { Admin } from "types/admin.type";

const routes = (currentAdmin?: Admin) => [
  {
    type: "collapse",
    name: currentAdmin?.lastName,
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "Déconnexion",
        key: "logout",
        route: "/logout",
        component: <Logout />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  { type: "title", title: "Pages", key: "title-pages" },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">volunteer_activism</Icon>,
    name: "Volontaires",
    key: "Volontaires",
    route: "/volontaires",
    component: <Volunteer />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">newspaper</Icon>,
    name: "Infolettre",
    key: "infolettre",
    route: "/infolettre",
    component: <Newsletter />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">edit_notifications</Icon>,
    name: "Notification",
    key: "Notification",
    route: "/notification",
    component: <Notification />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">interests</Icon>,
    name: "Contenu",
    key: "Contenu",
    route: "/contenu",
    component: <UpdateContent />,
    privateRoute: true,
  },
  {
    type: "collapse",
    noCollapse: true,
    icon: <Icon fontSize="medium">settings</Icon>,
    name: "Paramètres",
    key: "Paramètres",
    route: "/parametres",
    component: <ThankYou />,
    privateRoute: true,
  },
  {
    name: "Authentication",
    key: "authentication",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Basic",
            key: "basic",
            route: "/authentication/sign-in",
            component: <SignIn />,
          },
        ],
      },
      {
        name: "Sign Up",
        key: "sign-up",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/sign-up",
            component: <SignUp />,
          },
        ],
      },
      {
        name: "Reset Password",
        key: "reset-password",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/reset-password",
            component: <ResetCover />,
          },
        ],
      },
      {
        name: "Thank you",
        key: "thank-you",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/thank-you",
            component: <ThankYou />,
          },
        ],
      },
      {
        name: "Verify email",
        key: "verify-email",
        collapse: [
          {
            name: "Cover",
            key: "cover",
            route: "/authentication/verify-email",
            component: <VerifyEmail />,
            privateRoute: true,
          },
        ],
      },
    ],
  },
];

export default routes;
