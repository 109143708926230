import MDBox from "components/MDBox";
import { ETypeContent } from "../content.type";
import ContentUpdate from "./content-update";

function SectionActivity(): JSX.Element {
  return (
    <MDBox>
      <MDBox>
        <ContentUpdate
          title="Activités Hors Murs section 1"
          typeContent={ETypeContent.activity1}
          addLink1={false}
          addLink2={false}
          addSubBody={true}
        />
        <ContentUpdate
          title="Activités Hors Murs section 2"
          typeContent={ETypeContent.activity2}
          addLink1={false}
          addLink2={false}
        />
        <ContentUpdate
          title="Activités Hors Murs section 3"
          typeContent={ETypeContent.activity3}
          addLink2={false}
        />
      </MDBox>
    </MDBox>
  );
}

export default SectionActivity;
