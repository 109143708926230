import { Card, CircularProgress, Grid, Icon } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { useGetAllContent, useUpdateContents } from "../content.hook";
import { Content, ETypeContent, SubContent } from "../content.type";

type InitialValues = {
  titleFr: string;
  titleEn: string;
  dateFr: string;
  dateEn: string;
  bodyFr: string;
  bodyEn: string;
  subBodyFr: string;
  subBodyEn: string;
  link1: string;
  link2: string;
  subContent: SubContent[];
};

type Props = {
  title: string;
  typeContent: ETypeContent;
  addLink1?: boolean;
  addLink2?: boolean;
  addSubBody?: boolean;
  addPeriod?: boolean;
  addSubCentent?: boolean;
};

function ContentUpdate({
  title,
  typeContent,
  addLink1 = true,
  addLink2 = true,
  addSubBody = false,
  addPeriod = true,
  addSubCentent = false,
}: Props): JSX.Element {
  const { data, isLoading } = useGetAllContent();
  const updateContents = useUpdateContents();

  const initialValues: InitialValues = {
    titleFr: data?.filter((content) => content.id === typeContent)[0]?.title_fr,
    titleEn: data?.filter((content) => content.id === typeContent)[0]?.title_en,
    dateFr: data?.filter((content) => content.id === typeContent)[0]?.period_fr,
    dateEn: data?.filter((content) => content.id === typeContent)[0]?.period_en,
    bodyFr: data?.filter((content) => content.id === typeContent)[0]?.body_fr,
    bodyEn: data?.filter((content) => content.id === typeContent)[0]?.body_en,
    subBodyFr: data?.filter((content) => content.id === typeContent)[0]
      ?.subBody_fr,
    subBodyEn: data?.filter((content) => content.id === typeContent)[0]
      ?.subBody_en,
    link1: data?.filter((content) => content.id === typeContent)[0]?.link1,
    link2: data?.filter((content) => content.id === typeContent)[0]?.link2,
    subContent: data?.filter((content) => content.id === typeContent)[0]
      ?.subContent,
  };

  const validations = Yup.object().shape({
    titleFr: Yup.string().required("champs obligatoire"),
    titleEn: Yup.string().required("champs obligatoire"),
    dateFr: addPeriod && Yup.string().required("champs obligatoire"),
    dateEn: addPeriod && Yup.string().required("champs obligatoire"),
    bodyFr: Yup.string().required("champs obligatoire"),
    bodyEn: Yup.string().required("champs obligatoire"),
    subBodyFr: addSubBody && Yup.string().required("champs obligatoire"),
    subBodyEn: addSubBody && Yup.string().required("champs obligatoire"),
    link1: addLink1 && Yup.string().required("champs obligatoire"),
    link2: addLink2 && Yup.string().required("champs obligatoire"),
  });

  const handleSubmit = async (values: InitialValues, { resetForm }: any) => {
    console.log("values:", values);
    try {
      const contents: Content[] = [
        {
          id: typeContent,
          title_fr: values.titleFr,
          title_en: values.titleEn,
          period_fr: values.dateFr,
          period_en: values.dateEn,
          body_fr: values.bodyFr,
          body_en: values.bodyEn,
          subBody_fr: values.subBodyFr,
          subBody_en: values.subBodyEn,
          link1: values.link1,
          link2: values.link2,
          subContent: values.subContent,
        },
      ];
      await updateContents.mutateAsync(contents);
      resetForm({ values });
    } catch (error: any) {
      console.error("error:", error);
      alert(error);
    }
  };

  return (
    <>
      {isLoading ? (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
        >
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <MDBox mt={4}>
          <Card style={{ overflowY: "auto" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, dirty, isSubmitting }) => {
                const {
                  titleFr: titleFrV,
                  titleEn: titleEnV,
                  dateFr: dateFrV,
                  dateEn: dateEnV,
                  bodyFr: bodyFrV,
                  bodyEn: bodyEnV,
                  subBodyFr: subBodyFrV,
                  subBodyEn: subBodyEnV,
                  link1: link1V,
                  link2: link2V,
                } = values;

                return (
                  <Form id={"form-date-festival"} autoComplete="off">
                    <MDBox my={3} m={4}>
                      <MDBox mb={6}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} lg={6}>
                            <MDTypography variant="h4" fontWeight="medium">
                              {title}
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>

                      <MDBox mt={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Titre - FR"}
                              name={"titleFr"}
                              value={titleFrV}
                              error={errors.titleFr && touched.titleFr}
                              success={titleFrV?.length > 0 && !errors.titleFr}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Titre - EN"}
                              name={"titleEn"}
                              value={titleEnV}
                              error={errors.titleEn && touched.titleEn}
                              success={titleEnV?.length > 0 && !errors.titleEn}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                      {addPeriod && (
                        <MDBox mt={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={"text"}
                                label={"Date - FR"}
                                name={"dateFr"}
                                value={dateFrV}
                                error={errors.dateFr && touched.dateFr}
                                success={dateFrV?.length > 0 && !errors.dateFr}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={"text"}
                                label={"Date - EN"}
                                name={"dateEn"}
                                value={dateEnV}
                                error={errors.dateEn && touched.dateEn}
                                success={dateEnV?.length > 0 && !errors.dateEn}
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      )}
                      <MDBox mt={3}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Description - FR"}
                              name={"bodyFr"}
                              value={bodyFrV}
                              error={errors.bodyFr && touched.bodyFr}
                              success={bodyFrV?.length > 0 && !errors.bodyFr}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormField
                              type={"text"}
                              label={"Description - EN"}
                              name={"bodyEn"}
                              value={bodyEnV}
                              error={errors.bodyEn && touched.bodyEn}
                              success={bodyEnV?.length > 0 && !errors.bodyEn}
                            />
                          </Grid>
                        </Grid>
                      </MDBox>
                      {addSubBody && (
                        <MDBox mt={3}>
                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={"text"}
                                label={"Sous Description - FR"}
                                name={"subBodyFr"}
                                value={subBodyFrV}
                                error={errors.subBodyFr && touched.subBodyFr}
                                success={
                                  subBodyFrV?.length > 0 && !errors.subBodyFr
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={"text"}
                                label={"Sous Description - EN"}
                                name={"subBodyEn"}
                                value={subBodyEnV}
                                error={errors.subBodyEn && touched.subBodyEn}
                                success={
                                  subBodyEnV?.length > 0 && !errors.subBodyEn
                                }
                              />
                            </Grid>
                          </Grid>
                        </MDBox>
                      )}
                      <MDBox mt={3}>
                        <Grid container spacing={3}>
                          {addLink1 && (
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={"text"}
                                label={"Lien 1"}
                                name={"link1"}
                                value={link1V}
                                error={errors.link1 && touched.link1}
                                success={link1V?.length > 0 && !errors.link1}
                              />
                            </Grid>
                          )}
                          {addLink2 && (
                            <Grid item xs={12} sm={6}>
                              <FormField
                                type={"text"}
                                label={"Lien 2"}
                                name={"link2"}
                                value={link2V}
                                error={errors.link2 && touched.link2}
                                success={link2V?.length > 0 && !errors.link2}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </MDBox>
                      <>
                        {addSubCentent && (
                          <>
                            <MDBox marginY={2}>
                              <Grid
                                container
                                spacing={3}
                                alignItems="center"
                                pt={2}
                              >
                                <Grid item xs={12} lg={6}>
                                  <MDTypography
                                    variant="h5"
                                    fontWeight="regular"
                                  >
                                    Sous Contenu
                                  </MDTypography>
                                </Grid>
                              </Grid>
                            </MDBox>
                            <FieldArray
                              name="subContent"
                              render={(arrayHelpers) => {
                                return (
                                  <>
                                    {values.subContent &&
                                    values.subContent.length > 0
                                      ? values.subContent.map((row, index) => (
                                          <MDBox
                                            bgColor="#EEEEEE"
                                            borderRadius={5}
                                          >
                                            <MDBox
                                              display="flex"
                                              justifyContent="flex-end"
                                            >
                                              <MDButton
                                                variant="text"
                                                color="error"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                              >
                                                <Icon>delete</Icon>
                                              </MDButton>
                                            </MDBox>
                                            <MDBox
                                              borderRadius={5}
                                              mb={2}
                                              marginX={4}
                                            >
                                              <MDBox mt={3}>
                                                <Grid
                                                  key={index}
                                                  container
                                                  spacing={4}
                                                >
                                                  <Grid item xs={12} sm={6}>
                                                    <FormField
                                                      type={"text"}
                                                      label={"Titre - FR"}
                                                      name={`subContent.${index}.title_fr`}
                                                      defaultValue={
                                                        row.title_fr
                                                      }
                                                    />
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <FormField
                                                      type={"text"}
                                                      label={"Titre - FR"}
                                                      name={`subContent.${index}.title_en`}
                                                      defaultValue={
                                                        row.title_en
                                                      }
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </MDBox>
                                              <MDBox mt={3}>
                                                <Grid container spacing={3}>
                                                  <Grid item xs={12} sm={6}>
                                                    <FormField
                                                      type={"text"}
                                                      label={"Description - FR"}
                                                      name={`subContent.${index}.body_fr`}
                                                      defaultValue={row.body_fr}
                                                    />
                                                  </Grid>
                                                  <Grid item xs={12} sm={6}>
                                                    <FormField
                                                      type={"text"}
                                                      label={"Description - EN"}
                                                      name={`subContent.${index}.body_en`}
                                                      defaultValue={row.body_en}
                                                    />
                                                  </Grid>
                                                </Grid>
                                              </MDBox>
                                            </MDBox>
                                          </MDBox>
                                        ))
                                      : null}
                                    <MDBox
                                      display="flex"
                                      justifyContent="space-between"
                                      mt={4}
                                    >
                                      <MDButton
                                        type="button"
                                        // onClick={() => arrayHelpers.push({ date: "" })}
                                        onClick={() => {
                                          arrayHelpers.push({});
                                        }}
                                        variant="outlined"
                                        color="info"
                                      >
                                        + Ajouter
                                      </MDButton>
                                    </MDBox>
                                  </>
                                );
                              }}
                            />
                          </>
                        )}
                      </>
                    </MDBox>
                    <MDBox p={2} display="flex" justifyContent="flex-end">
                      <MDButton
                        disabled={!dirty || isSubmitting}
                        type="submit"
                        variant="gradient"
                        color="info"
                      >
                        Valider
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: "inherit",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </MDButton>
                    </MDBox>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </MDBox>
      )}
    </>
  );
}

export default ContentUpdate;
