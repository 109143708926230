export type Content = {
  id: string;
  title_en?: string;
  title_fr?: string;
  body_en?: string;
  body_fr?: string;
  period_en?: string;
  period_fr?: string;
  subBody_en?: string;
  subBody_fr?: string;
  link1?: string;
  link2?: string;
  subContent?: SubContent[];
};

export type SubContent = {
  id: number;
  contentId?: string;
  title_en?: string;
  title_fr?: string;
  body_en?: string;
  body_fr?: string;
};

export type DateVolunteer = {
  date: string;
};

export enum ETypeContent {
  dateFestival = "date_festival",
  dateMusic = "date_music",
  dateFamily = "date_family_activity",
  dateSport = "date_sport",
  dateActivity = "date_other_activity",
  music1 = "music_1",
  music2 = "music_2",
  music3 = "music_3",
  music4 = "music_4",
  sport1 = "sport_1",
  sport2 = "sport_2",
  sport3 = "sport_3",
  activity1 = "activity_1",
  activity2 = "activity_2",
  activity3 = "activity_3",
  ticket1 = "ticket_1",
  ticket2 = "ticket_2",
  ticket3 = "ticket_3",
  ticket4 = "ticket_4",
  ticket5 = "ticket_5",
  family1 = "family_1",
  family2 = "family_2",
  family3 = "family_3",
  form1 = "form_1",
}
