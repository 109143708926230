import Navigation from "components/NavigationPage/Navigation";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DateFestival from "./components/date-festival";
import SectionMusic from "./components/section-music";
import SectionFamily from "./components/section-family";
import SectionSport from "./components/section-sport";
import SectionActivity from "./components/section-activity";
import DateVolunteer from "./components/date-volunteer";
import SectionTicket from "./components/section-ticket";

function UpdateContent(): JSX.Element {
  const tabs = [
    {
      label: "Date Festival",
      content: <DateFestival />,
      mode: "inside",
    },
    {
      label: "Musique",
      content: <SectionMusic />,
      mode: "inside",
    },
    {
      label: "Famille",
      content: <SectionFamily />,
      mode: "inside",
    },
    {
      label: "Sport",
      content: <SectionSport />,
      mode: "inside",
    },
    {
      label: "Activités Hors Murs",
      content: <SectionActivity />,
      mode: "inside",
    },
    { label: "Billetterie", content: <SectionTicket />, mode: "inside" },
    { label: "Formulaire", content: <DateVolunteer />, mode: "inside" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Navigation stickyNavbar={true} tabs={tabs}>
        <></>
      </Navigation>
    </DashboardLayout>
  );
}

export default UpdateContent;
