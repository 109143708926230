import React, { ReactNode, useState } from "react";
import Modal from "components/Modal/modal";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import MDBox from "components/MDBox";

interface TabData {
  label: string;
  content: ReactNode;
  mode: string;
}

interface Props {
  stickyNavbar?: boolean;
  tabs: TabData[];
  children: ReactNode;
}

const Navigation = ({ stickyNavbar, tabs, children }: Props): JSX.Element => {
  const [tabValue, setTabValue] = useState<number>(0);
  const [modalState, setModalState] = useState<boolean>(false);
  const [renderedTabs, setRenderedTabs] = useState<{ [key: number]: boolean }>({
    0: true, // Rendre le premier onglet actif dès le début
  });
  const [renderedModalsTabs, setRenderedModalsTabs] = useState<{
    [key: number]: boolean;
  }>({});

  const handleSetTabValue = (event: any, newValue: number) => {
    if (tabs[newValue]?.mode === "modal") {
      if (!renderedModalsTabs[newValue]) {
        setRenderedModalsTabs((prevRenderedTabs) => ({
          ...prevRenderedTabs,
          [newValue]: true,
        }));
      }
      setModalState(true);
    } else {
      setTabValue(newValue);
      // Si l'onglet n'a pas encore été rendu, marquez-le comme rendu
      if (!renderedTabs[newValue]) {
        setRenderedTabs((prevRenderedTabs) => ({
          ...prevRenderedTabs,
          [newValue]: true,
        }));
      }
    }
  };

  return (
    <MDBox mt={stickyNavbar ? 3 : 10}>
      <Grid container>
        <Grid item xs={12} sm={12} lg={12}>
          <MDBox minWidth={350}>
            <AppBar position="static">
              <Tabs
                orientation="horizontal"
                value={tabValue}
                onChange={handleSetTabValue}
              >
                {tabs.map((tab, index) => (
                  <Tab key={index} label={tab.label} />
                ))}
              </Tabs>
            </AppBar>
          </MDBox>
        </Grid>
      </Grid>
      <div>
        {/* Conditionally render content based on the selected tab */}
        {tabs.map((tab, index) =>
          tab.mode === "inside" ? (
            <div
              key={index}
              style={{ display: tabValue === index ? "block" : "none" }}
            >
              {renderedTabs[index] && tab.content}
            </div>
          ) : (
            <Modal
              key={index}
              title={tab.label}
              openModal={modalState}
              body={<>{renderedModalsTabs[index] && tab.content}</>}
              handleAction={() => setModalState(!modalState)}
              action={true}
              actionLabel={"Fermer"}
              valid={false}
              handleClose={() => setModalState(!modalState)}
              cancel={false}
              fullScreen={true}
            />
          )
        )}
        {/*tabs
          .filter((tab) => tab.mode === "modal")
          .map((tab, index) => (
            
          ))*/}
      </div>

      {children}
    </MDBox>
  );
};

// Declaring default props for Navigation
Navigation.defaultProps = {
  stickyNavbar: false,
};

export default Navigation;
