import { Card, CircularProgress, Grid, Icon } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { FieldArray, Form, Formik } from "formik";
import isoToDateInputValue from "utils/isoToDateInputValue.util";
import * as Yup from "yup";
import {
  useGetAllDateVolunteer,
  useUpdateDateVolunteer,
} from "../content.hook";
import { DateVolunteer, ETypeContent } from "../content.type";
import moment from "moment";
import ContentUpdate from "./content-update";

type InitialValues = {
  dates: DateVolunteer[];
};

function SettingsDateVolunteer(): JSX.Element {
  const { data, isLoading } = useGetAllDateVolunteer();
  const updateDateVolunteer = useUpdateDateVolunteer();

  const initialValues: InitialValues = {
    dates: data,
  };

  const validations = Yup.object().shape({
    dates: Yup.array()
      .of(
        Yup.object().shape({
          date: Yup.date()
            .required("Date is required")
            .typeError("Invalid date"), // Message d'erreur si la valeur n'est pas une date
        })
      )
      .required("You must provide at least one date")
      .min(1, "At least one date is required"), // Assure qu'au moins un objet est dans l'array
  });

  const handleSubmit = async (values: InitialValues, { resetForm }: any) => {
    try {
      await updateDateVolunteer.mutateAsync(values.dates);
      resetForm({ values });
    } catch (error: any) {
      console.error("error:", error);
      alert(
        "Une erreur est survenue lors de la saisie de vos dates. Veuillez vérifier la cohérence des dates et éviter les doublons."
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="60vh"
        >
          <CircularProgress color="inherit" />
        </MDBox>
      ) : (
        <MDBox mt={4}>
          <MDBox mb={4}>
            <Card>
              <ContentUpdate
                title="Section plus de disponibilités"
                typeContent={ETypeContent.form1}
                addLink1={false}
                addLink2={false}
                addPeriod={false}
              />
            </Card>
          </MDBox>
          <Card style={{ overflowY: "auto" }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={handleSubmit}
            >
              {({ values, isValid, touched, dirty, isSubmitting }) => {
                return (
                  <Form id={"form-date-festival"} autoComplete="off">
                    <MDBox my={3} m={4}>
                      <MDBox mb={6}>
                        <Grid container spacing={3} alignItems="center">
                          <Grid item xs={12} lg={6}>
                            <MDTypography variant="h4" fontWeight="medium">
                              Section dates disponibilités volontaire
                            </MDTypography>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <FieldArray
                        name="dates"
                        render={(arrayHelpers) => (
                          <>
                            {values.dates && values.dates.length > 0
                              ? values.dates.map((row, index) => (
                                  <Grid key={index} container spacing={4}>
                                    <Grid item xs={10}>
                                      <FormField
                                        type="date"
                                        label="Date"
                                        name={`dates.${index}.date`}
                                        // Utilisation de la fonction pour convertir la date ISO
                                        value={isoToDateInputValue(row.date)}
                                      />
                                    </Grid>
                                    <Grid item xs={2}>
                                      <MDBox mt={1}>
                                        <MDButton
                                          variant="text"
                                          color="error"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <Icon>delete</Icon>
                                        </MDButton>
                                      </MDBox>
                                    </Grid>
                                  </Grid>
                                ))
                              : null}
                            <MDBox
                              display="flex"
                              justifyContent="space-between"
                              mt={4}
                            >
                              <MDButton
                                type="button"
                                // onClick={() => arrayHelpers.push({ date: "" })}
                                onClick={() => {
                                  const lastDate = moment(
                                    values.dates[values.dates.length - 1].date
                                  );
                                  const nextDate = lastDate.add(1, "days");
                                  arrayHelpers.push({
                                    date: nextDate.format("YYYY-MM-DD"),
                                  });
                                }}
                                variant="outlined"
                                color="info"
                              >
                                + Ajouter Date
                              </MDButton>
                              <MDButton
                                disabled={!dirty || isSubmitting || !isValid}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                Valider
                                {isSubmitting && (
                                  <CircularProgress
                                    size={24}
                                    sx={{
                                      color: "inherit",
                                      position: "absolute",
                                      top: "50%",
                                      left: "50%",
                                      marginTop: "-12px",
                                      marginLeft: "-12px",
                                    }}
                                  />
                                )}
                              </MDButton>
                            </MDBox>
                          </>
                        )}
                      />
                    </MDBox>
                  </Form>
                );
              }}
            </Formik>
          </Card>
        </MDBox>
      )}
    </>
  );
}

export default SettingsDateVolunteer;
