import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import {
  findAllContents,
  findAllDateVolunteer,
  updateContents,
  updateDateVolunteer,
} from "./content.service";
import { Content, DateVolunteer } from "./content.type";

export enum KeyQueryContent {
  getallContent = "getallContent",
  getallDateVolunteer = "getallDateVolunteer",
}

export const useGetAllContent = (): UseQueryResult<Content[]> => {
  return useQuery([KeyQueryContent.getallContent], () => findAllContents());
};

export const useGetAllDateVolunteer = (): UseQueryResult<DateVolunteer[]> => {
  return useQuery([KeyQueryContent.getallDateVolunteer], () =>
    findAllDateVolunteer()
  );
};

export const useUpdateContents = () => {
  return useMutation({
    mutationFn: (contents: Content[]) => updateContents(contents),
  });
};

export const useUpdateDateVolunteer = () => {
  return useMutation({
    mutationFn: (contents: DateVolunteer[]) => updateDateVolunteer(contents),
  });
};
