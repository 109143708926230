import checkout from "pages/volunteer/schemas/form";

const {
  formField: { startDate, endDate },
} = checkout;

const initialValues = {
  [startDate.name]: "",
  [endDate.name]: "",
};

export default initialValues;
