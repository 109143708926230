import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { findAllVolunteer } from "./volunter.service";

export enum KeyQueryVolunteer {
  getallVolunteer = "getallVolunteer",
}

export const useGetAllVolunteer = (): UseQueryResult<any> => {
  return useQuery([KeyQueryVolunteer.getallVolunteer], () =>
    findAllVolunteer()
  );
};
