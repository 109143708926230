import checkout from "pages/notification/schemas/form";
import * as Yup from "yup";

const {
  formField: { title, message },
} = checkout;

const validations = Yup.object().shape({
  [title.name]: Yup.string()
    .required(title.errorMsgKey)
    .test(
      "len",
      "La longueur doit être supérieure à 5 caractères",
      (value) => value.length > 5
    ),

  [message.name]: Yup.string()
    .required(message.errorMsgKey)
    .test(
      "len",
      "La longueur doit être supérieure à 5 caractères",
      (value) => value.length > 5
    ),
});

export default validations;
