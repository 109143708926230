import api from "config/axios.config";
import { Notification, PushNotification } from "./notification.type";

export const sendPushNotification = async (data: PushNotification): Promise<Notification> => {
  try {
    return api.post(`/notifications/send`, data);
  } catch (error) {
    console.error("Error send notification:", error);
  }
};

export const getNotificationsPending = async () => {
  try {
    return api.get(`/notifications/status/pending`);
  } catch (error) {
    console.error("Error send notification:", error);
  }
};

export const deleteNotification = (
  notificationId: string
): Promise<Notification> => {
  return api.delete(`/notifications/${notificationId}`);
};
