import MDBox from "components/MDBox";
import { ETypeContent } from "../content.type";
import ContentUpdate from "./content-update";

function SectionSport(): JSX.Element {
  return (
    <MDBox>
      <MDBox>
        <ContentUpdate
          title="Sport section 1"
          typeContent={ETypeContent.sport1}
        />
        <ContentUpdate
          title="Sport section 2"
          typeContent={ETypeContent.sport2}
          addLink2={false}
        />
        <ContentUpdate
          title="Sport section 3"
          typeContent={ETypeContent.sport3}
          addLink2={false}
        />
      </MDBox>
    </MDBox>
  );
}

export default SectionSport;
