import * as Yup from "yup";
import checkout from "pages/volunteer/schemas/form";
import i18next from "i18n";

const {
  formField: { startDate, endDate },
} = checkout;

const validations = Yup.object().shape({
  [startDate.name]: Yup.string().required(i18next.t(startDate.errorMsgKey)),
  [endDate.name]: Yup.string().required(i18next.t(endDate.errorMsgKey)),
});

export default validations;
